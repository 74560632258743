/* Copyright 2020. Plesk International GmbH. All rights reserved. */

@import '~bulma/css/bulma.css';

body {
    background-image: url('./images/trianglify.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
}
