/* Copyright 2020. Plesk International GmbH. All rights reserved. */

.space {
    min-height: calc(50vh - 180px);
    transition: min-height 0.5s;
}

.space--fake {
    min-height: calc(50vh - 83px);
}

.space--narrow {
    min-height: 0vh;
}

.form {
    padding-top: 1.5rem;
}

.form__title {
    margin-top: 1.5rem;
}

.form + .container {
    margin-top: 1.5rem;
}

.screenshot + .screenshot {
    margin-top: 1.5rem;
}

.fake {
    text-align: center;
}
